import { defineCustomElements } from "@riverty/web-components/loader";
import { LogoComponent } from "./components";
import { Settings } from "./utils/settings";
import { vMaska } from "maska/vue";
import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import VueMatomo from "vue-matomo";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

defineCustomElements(window);
app.component("Logo", LogoComponent);
app.use(router);
app.use(pinia);
app.directive("maska", vMaska);

fetch("/otp/settings.json").then(async (response) => {
    const settings = await response?.json();
    Settings.init(settings);
    const matomoSiteId = Settings.get().MATOMO_SITE_ID;

    //motomo traking for live and pt
    if (matomoSiteId == 46 || matomoSiteId == 82) {
        app.use(VueMatomo, {
            host: 'https://analytics.flow.riverty.com/',
            siteId: matomoSiteId
        });
    }
    
    app.mount("#app");

    //motomo traking for live and pt
    if (matomoSiteId == 46 || matomoSiteId == 82) {
        window._paq.push(["setExcludedQueryParams", ["\/.*\/"]]);
        window._paq.push(["setExcludedReferrers", ["prod.riverty.com"]]);
        window._paq.push(['disableCookies']);
        window._paq.push(['trackPageView']); // To track a page view
        window._paq.push(['enableLinkTracking']);
    }
});
