import { FlowType, flowTypeEnumToString, LanguageCode, OtpType, languageCodeStringToEnum, otpTypeEnumToString } from "@/enums";
import { getTextResources } from "tests/entities";
import { TextResourceList, CheckoutInfo, OtpResponse } from "@/entities";
import { getTranslations, loading, replaceState, translation } from "@/utils/common";
import { defineComponent } from "vue";
import { get } from "@/utils/httpRequest";
import { useRouter } from "vue-router";
import { otpStore } from "@/store";

export default defineComponent({
    name: "IntroView",
    props: {
        scaSessionId: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            data: new CheckoutInfo(),
            redirectUrl: <string | null>"",
            selectedOtpType: <OtpType | string>OtpType.Unset,
            OtpType,
            FlowType,
            textResourceList: new TextResourceList(),
            store: otpStore(),
            otpTypeEnumToString,
            flowTypeEnumToString,
        };
    },
    async created() {
        replaceState();
        this.data = this.store.otp;
        this.redirectUrl = useRouter().currentRoute.value.query.merchantUrl as string;
        if (!this.redirectUrl) {
            this.redirectUrl = this.data.redirectUrl;
        }

        loading();
        try {
            const response = <OtpResponse>await get(`api/verifyotp/init/${this.scaSessionId}?merchantUrl=${this.redirectUrl}`);

            //redirect to error (or restart) view
            if (!response.isSuccess) {
                this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${response.data?.redirectUrl ?? this.redirectUrl}`);
                return;
            }

            //redirect back to merchant
            if (response.data && response.data.redirectUrl && response.returnToMerchant) {
                window.location.replace(response.data.redirectUrl);
                return;
            }

            //can't continue if no data
            if (!response.data) {
                this.$router.replace(`/error/${this.scaSessionId}?merchantUrl=${this.redirectUrl}`);
                return;
            }

            this.data = response.data;
            this.store.otp = this.data;

            if (this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpEmail) || this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpEmailDob)) {
                this.selectedOtpType = OtpType.Email;
            } else if (this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpSms) || this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpSmsDob)) {
                this.selectedOtpType = OtpType.Sms;
            } else if (this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpSmsEmail)) {
                if (this.data.email && !this.data.phoneNumber) {
                    this.selectedOtpType = OtpType.Email;
                } else if (!this.data.email && this.data.phoneNumber) {
                    this.selectedOtpType = OtpType.Sms;
                } else {
                    //can't continue if neither email or phoneNr is given
                    this.$router.replace(`/error/${this.scaSessionId}?merchantUrl=${this.redirectUrl}`);
                    return;
                }
            } else {
                this.selectedOtpType = OtpType.Unset;
            }
        } catch (e) {
            console.log("Error while loading page data.", e);
        } finally {
            loading();
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            loading();
            try {
                this.textResourceList = (await this.getTextResources(this.data.countryCode, languageCodeStringToEnum(this.data.conversationLanguage))) as TextResourceList;
            } catch {
                return;
            } finally {
                loading();
            }
        },
        hasSteps() {
            return this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpEmailDob) || this.data.flowType == flowTypeEnumToString(FlowType.VerifyOtpSmsDob);
        },
        async redirect() {
            if (this.selectedOtpType == OtpType.Unset) return;

            const response = <OtpResponse>await get(`api/verifyotp/requestcode/${otpTypeEnumToString(this.selectedOtpType)}/${this.scaSessionId}`);
            if (response.data) {
                this.store.otp = response.data;
                this.data = response.data;
            }

            if (!response.isSuccess) {
                //redirect to error view
                this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${this.redirectUrl}`);
            }

            //redirect back to merchant
            if (response.data && response.data.redirectUrl && response.returnToMerchant) {
                window.location.replace(response.data.redirectUrl);
            }

            //redirect to next step
            if (response.view) {
                this.$router.replace(`/${response.view}/${this.scaSessionId}`);
            }
        },
        async goBack() {
            //change status to "UserCancel" and redirect to merchant
            const response = await get(`api/verifyotp/cancel/${this.scaSessionId}`);
            if (!response.isSuccess) {
                //redirect to error view
                this.$router.replace(`/${response.view}/${this.scaSessionId}?merchantUrl=${response.data.redirectUrl}`);
            }

            const redirectUrl = response.data.redirectUrl ?? "https://www.riverty.com";

            window.location.replace(redirectUrl);
        },
        async getTextResources(countryCode: string | null | undefined, languageCode: LanguageCode, agent: unknown | null = null): Promise<TextResourceList | getTextResources> {
            const sysnameList = [
                "OtpBackButton",
                "OtpIntroTitle",
                "OtpIntroDescription",
                "OtpIntroEmailDescription",
                "OtpIntroEmailDobDescription",
                "OtpIntroSmsDescription",
                "OtpIntroSmsDobDescription",
                "OtpIntroWeSendVerificationCodeTo",
                "OtpIntroSendCodeButton",
                "OtpStart",
                "OtpEmailCode",
                "OtpSmsCode",
                "OtpBirthDateLabel",
            ];

            return await getTranslations(sysnameList, countryCode, languageCode, agent);
        },
        getTranslation(sysName: string, replace: string[] = []): string {
            return translation(this.textResourceList?.textResourceItems, sysName, replace);
        },
    },
});
