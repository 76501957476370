import { CheckoutInfo } from "@/entities";
import { defineStore } from "pinia";

export interface State {
    otp: CheckoutInfo;
}

export const otpStore = defineStore("otpStore", {
  state: () => ({
    otp: new CheckoutInfo(),
  }),
  persist: true,
})
